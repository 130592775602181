import './App.css';
import { Provider } from 'react-redux'
import { store } from './store';
import {Cart} from "../components/cart/Cart.js";
import {Total} from "../components/total/Total.js";
import {Voucher} from "../components/voucher/Voucher.js";

function App() {
  return (
	  <Provider store={store}>
		  <div className="App">
			  <Cart />
			  <Total />
			  <Voucher />
		  </div>
	  </Provider>
  );
}

export default App;
