import { configureStore, createAction, createReducer } from "@reduxjs/toolkit"
import { PouletCroquant, SuperCremeux } from "../common/models";

export const addProduct = createAction("ADD_PRODUCT");
export const removeProduct = createAction("REMOVE_PRODUCT");
export const applyVoucher = createAction("APPLY_VOUCHER");
export const updateFirstname = createAction("UPDATE_FIRSTNAME");

let initialState = {
	value: null,
	list: [
		SuperCremeux,
		PouletCroquant,
	]
};

const reducer = createReducer(initialState, function(builder){
	builder.addCase(addProduct, (state, action) => {
		const listWithNewProduct = [...state.list, action.payload]
		return {...state, list: listWithNewProduct}
	});
	builder.addCase(removeProduct, (state, action) => {
		const list = state.list.filter(
			(item, index) => index !== action.payload
		)
		return {...state, list: list}
	});
	builder.addCase(applyVoucher, (state, action) => {
		const withVoucherList = state.list.map(
			item => item.title === 'Super Crémeux' ? ({...item, price: action.payload.price}) : item
		)
		return {...state, list: withVoucherList}
	});
	builder.addCase(updateFirstname, (state, action) => {
		const owner = {...state.owner, firstName: action.payload}
		return {...state, owner}
	});
})

export const store = configureStore(
	{
		preloadedState: initialState,
		reducer
	}
)