import { useSelector,useStore } from "react-redux";
import { SuperCremeux } from "../../common/models.js";
import {getProductList} from "../../app/selectors.js";
import {addProduct} from "../../app/store.js";


export const Cart = () => {
	const store = useStore();
	const list = useSelector(getProductList);

	return <div className="Selection">
		<h1>Choisir son menu</h1>
		<div className="CartNavBar">
			<button onClick={() => store.dispatch(addProduct(SuperCremeux))}>Ajouter un super crémeux</button>
		</div>
		{
			list.map((item, index) => <span key={index} className="SelectedProduct">{item.title} {item.price} €</span>
			)
		}
	</div>
};