export const DoubleCantal = {
	title: 'Double Cantal',
	price: 15.99,
}

export const SuperCremeux = {
	title: 'Super Crémeux',
	price: 14.99,
}

export const PouletCroquant = {
	title: 'Poulet Croquant',
	price: 17.99,
}